@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: LionelSteam;
    src: url('./assets/fonts/lionel_text_steam.ttf') format('opentype');
  }

  @font-face {
    font-family: AvenirLight;
    src: url('./assets/fonts/avenir-arabic-light.otf') format('opentype');
  }
  @font-face {
    font-family: AvenirMedium;
    src: url('./assets/fonts/avenir-arabic-medium.otf') format('opentype');
  }
  @font-face {
    font-family: AvenirBold;
    src: url('./assets/fonts/avenir-bold.ttf') format('opentype');
  }

  * {
    scroll-behavior: smooth;
  }
  section {
    @apply px-5 sm:px-6 lg:px-0;
  }

  button {
    @apply font-BN uppercase tracking-[0.2em];
  }
}

.hero-section {
  background-image: url('https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689325569/banner.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
